@font-face {
  font-family: 'Arimo';
  src: url('./assets/fonts/Arimo-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Alice';
  src: url('./assets/fonts/Alice-Regular.ttf') format('truetype');
}


body {
  margin: 0;
  background-image: url('assets/background.png');
  background-repeat: repeat;
  background-position: center;
  font-family: 'Alice', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.mobile-nav-expanded, body.modal-image-shown {
  overflow-y: hidden;
}