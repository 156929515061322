h4 {
  text-decoration: underline;
}

.faq-item {
  margin: 16px 0;
}

.faq__question {
  text-decoration: underline;
  padding-bottom: 4px;
}

#faq__page ul {
  margin-top: 0;
}