:root {
  --mobile-nav-height: 64px;
}

nav.desktop-navigation  {
  display: block;
  position: fixed;
  right: 0;
  margin: 20px 0 0 0;
  padding-left: 100px;
  padding-top: 30px;
  padding-bottom: 50px;
  background-image: url(../assets/nav-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}

nav.desktop-navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav.desktop-navigation li {
  float: left;
}

nav.desktop-navigation li a {
  display: block;
  color: white; /*var(--almost-black); */
  text-align: center;
  padding: 8px;
  text-decoration: none;
  border: 2px solid transparent;
  margin-right: 4px;
}

nav.desktop-navigation li a:hover, .nav-link__active {
  background-image: url(../assets/nav-link-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* mobile */
.mobile-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.mobile-navigation__header {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.mobile-navigation__header-logo {
  width: 60px;
}

.mobile-navigation button {
  width: 50px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--green);
}

.mobile-navigation__menu.expanded {
  position: absolute;
  top: 0;
  display: block;
  float: right;
  width: 100%;
  height: 100vh;
  background-color: rgb(0,0,0,0.85);
  padding-top: var(--mobile-nav-height);
  animation: 0.4s ease-in-out 0s 1 basicFadeIn;
}

.mobile-navigation__menu.expanded .socials-links {
  position: fixed;
}

.mobile-navigation__menu {
  display: none;
}

.mobile-navigation__menu ul {
  list-style-type: none;
  text-align: center;
  padding: 0;
}

.mobile-navigation__menu li {
  width: 100%;
  padding: 8px 0;
}

.mobile-navigation__menu li a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 8px;
}

.mobile-navigation__menu li a:hover {
  background-image: url(../assets/nav-link-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}