#flash-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: auto;
}

#flash-grid {
  padding: 15px 0 15px 0;
}

.flash-image {
  width: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

#booking-desktop iframe {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2500px;
}

/* mobile */
#booking-mobile iframe {
  position: absolute;
  left: 0;
  width: 100%;
  height: 800px;
}

#booking-mobile .mobile-footer {
  bottom: -800px;
}
