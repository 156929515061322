#portfolio-desktop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: auto;
  padding-bottom: 5%;
}

.portfolio-image {
  width: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

/* mobile */
#portfolio-mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: auto;
  margin-top: 40px;
}
