#panel-desktop__main {
  /*background-color: #ebebeb;*/
  position: fixed;
  height: 100vh;
}

.panel-home-width {
  width: 60%;
}

.panel-width {
  width: 40%;
}

.animate-home-in {
  animation: 0.9s ease-in-out 0s 1 homeIn;
}

@keyframes homeIn {
  from {
    width: 40%;
  }
  to {
    width: 60%;
  }
}

.animate-home-out {
  animation: 0.9s ease-in-out 0s 1 homeOut;
}

@keyframes homeOut {
  from {
    width: 60%;
  }
  to {
    width: 40%;
  }
}

.subheader-fade-in {
  animation: 1s ease-in-out 0s 1 subheaderFadeIn;
}

@keyframes subheaderFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#panel-desktop__background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8;
  background-image: url('../../assets/panel-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#panel-desktop__header-section {
  position: relative;
  top: 20%;
}

#panel-desktop-home__header-section {
  position: relative;
  top: 40%;
}

#panel-desktop__header {
  color: var(--almost-black);
  text-align: center;
  font-size: 4.5rem;
  margin: auto;
}

.panel-desktop__subheader {
  font-size: 2.5rem;
  text-align: center;
  margin: auto;
  color: var(--sub-header); /*var(--green);*/
}

.subheader-decorator {
  font-size: 2rem;
}

/* mobile */
.panel-mobile__main {
  width: 100%;
  height: 200px;
}

.panel-mobile__background {
  position: absolute;
  width: 100%;
  height: 200px;
  opacity: 0.8;
  background-image: url('../../assets/panel-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#panel-mobile__header-section {
  position: relative;
  top: 30%;
}

#panel-mobile-home__header-section {
  position: relative;
  top: 40%;
}

#panel-mobile__header {
  color: var(--almost-black);
  text-align: center;
  font-size: 2.5rem;
  margin: auto;
}

.panel-mobile__subheader {
  font-size: 1.75rem;
  text-align: center;
  margin: auto;
  color: var(--sub-header); /*var(--green);*/
}