#background__watermark {
  background-image: url('../assets/logo.png');
  background-position: right 50px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 0;
  animation: 1s ease-in 0s 1 fadeIn;
  opacity: 0.1;
}

#background__watermark.desktop-watermark {
  background-size: 500px 500px;
}

#background__watermark.mobile-watermark {
  background-position: right 120px;
  background-size: 250px 250px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.1;
  }
}

/* socials */
.socials-links {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: var(--almost-black);
  padding: 8px;
}

.icon:hover {
  color: var(--green);
}