.home-desktop__main {
  position: absolute;
  right: 0;
  width: 40%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#desktop-home {
  width: 40%;
  text-align: center;
}

#desktop-home .home-main__logo {
  width: 300px;
  height: 300px;
  opacity: 0.9;
}

/* mobile */
#mobile-home {
  text-align: center;
}

#mobile-home .home-main__logo {
  width: 200px;
  height: 200px;
  opacity: 0.9;
}

/* mailing list */
.mailing-form__text {
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 40px;
  margin-bottom: 8px;
}

.mc__field-container {
  padding-bottom: 5px;
}

.mc__input {
  border: 1px solid var(--logo-brown);
  border-radius: 4px;
  outline: none;
  padding: 4px;
  width: 60%;
  max-width: 180px;
  margin-right: 5px;
  font-family: 'Alice', sans-serif;
}

.mc__input:focus {
  border: 1px solid var(--green);
}

.mc__submit-button {
  background-color: var(--logo-brown);
  border: 1px solid var(--logo-brown);
  border-radius: 4px;
  color: white;
  padding: 4px;
  width: 30%;
  max-width: 75px;
  font-family: 'Alice', sans-serif;
  appearance: none;
}

.mc__submit-button:hover {
  background-color: var(--green);
  border: 1px solid var(--green);
  cursor: pointer;
}

.mc__submit-button:disabled {
  background-color: #999;
  border: 1px solid #999;
  cursor: auto;
}

.mc__status-text {
  margin-top: 2px;
  font-size: 0.9rem;
}

.mc__success-text {
  color: var(--green);
}

.mc__error-text {
  color: maroon;
}