:root {
  --green: #919147;
  --almost-black: #3f3f3f;
  --sub-header: #999999;
  --logo-brown: #66605f;
}

.App {
  position: relative;
}

@media screen and (min-width: 992px) {
  .App {
    height: 100vh;
  }
}

@media screen and (max-width: 991px) {
  html, body, #root {
    height: 100%;
  }
  
  .App {
    min-height: 100%;
  }
}

.custom-link {
  text-decoration: none;
  color: var(--green);
}

.page-container {
  display: flex;
  flex-direction: row;
}

.desktop-main {
  position: absolute;
  right: 0;
  width: 60%;
  height: calc(100% - 150px);
  margin-top: 150px;
}

.desktop-main__content {
  padding: 0 10% 10% 10%;
  max-width: 1000px;
  margin: auto;
}

.desktop-main__fade-in-shift {
  animation: 1s ease-in-out 0s 1 desktopMainFadeInShift;
}

@keyframes desktopMainFadeInShift {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.desktop-main__fade-in {
  animation: 1s ease-in-out 0s 1 basicFadeIn;
}

@keyframes basicFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.desktop-main__full {
  height: 100%;
}

.image-square {
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  cursor: zoom-in;
}

.image-modal__exit {
  position: fixed;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: var(--green);
  z-index: 99;
}

.image-modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 99;
  animation: 0.4s ease-in-out 0s 1 basicFadeIn;
}

.image-modal img {
  max-height: 90%;
  max-width: 90%;
  margin: auto;
  z-index: 99;
}


.image-modal__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0, 0.85);
  cursor: pointer;
  z-index: 50;
}

.image-modal.image-modal-mobile {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* mobile */
.mobile-main {
  position: relative;
  margin-top: 40px;
  z-index: 1;
}

.mobile-main__content {
  padding: 0 10% 100px 10%;
  margin: auto;
}

.mobile-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.mobile-footer .socials-links {
  bottom: 0;
}